var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',[_vm._t("header",function(){return [_c('CIcon',{attrs:{"name":"cil-grid"}}),_vm._v(" "+_vm._s(_vm.caption)+" ")]})],2),_c('CCardBody',[_c('CDataTable',{attrs:{"hover":"","striped":"","border":_vm.border,"small":_vm.small,"fixed":_vm.fixed,"items":_vm.p_trees,"fields":_vm.fields,"items-per-page":_vm.small ? 20 : 8,"dark":_vm.dark,"pagination":"","table-filter":"","items-per-page-select":"","sorter":""},scopedSlots:_vm._u([{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")])]}},{key:"updated_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.updated_at))+" ")])]}},{key:"picture",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',{attrs:{"href":'https://drive.google.com/file/d/' + item.path + '/view',"target":"_blank"}},[_vm._v("view")])])]}},{key:"no_trees",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.addComma(item.no_trees))+" ")])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('button',{staticClass:"btn btn-sm btn-outline-secondary",on:{"click":function($event){$event.preventDefault();return _vm.updatePlantedTrees(item)}}},[_c('i',{staticClass:"bi bi-pen text-primary"})])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }